import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

export const CreatePersona = (props) => {

    const [listaPuestos, setListaPuestos] = useState([]);
    /**
     * Función para recuperar lista de puestos de la empresa
     */
    useEffect(() => {
        Axios.get("/api/get/puestos",{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
                //dependencia
                        setListaPuestos(response.data);
            });
    },[]);

    return (
        <Modal show={props.showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Agregar nuevo responsable
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={props.validatedCreate} onSubmit={props.handleSubmitNew}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Primer nombre</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Primer nombre"
                                onChange={(e) => { props.setPNombre(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese el primer nombre!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Segundo nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Segundo nombre"
                                onChange={(e) => { props.setSNombre(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>Tercer (o más) nombre(s)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Tercer nombre"
                                onChange={(e) => { props.setTNombre(e.target.value) }}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>Primer apellido</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Primer apellido"
                                onChange={(e) => { props.setPApellido(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese el primer apellido!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>Segundo apellido</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Segundo apellido"
                                onChange={(e) => { props.setSApellido(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom06">
                            <Form.Label>Apellido de casada</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Apellido de casada"
                                onChange={(e) => { props.setTApellido(e.target.value) }}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom07">
                            <Form.Label>Correo electronico</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="mi@correo.com"
                                onChange={(e) => { props.setCorreo(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese el correo electronico!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom09">
                            <Form.Label>Sexo</Form.Label>
                            <Form.Select onChange={(e) => props.setSexo(e.target.value)} required>
                                <option value="">Seleccione sexo</option>
                                <option value="1">Masculino</option>
                                <option value="2">Femenino</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, seleccione sexo!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom10">
                            <Form.Label>Fecha de nacimiento</Form.Label>
                            <Form.Control required type='date' onChange={(e) => { props.setFechaNacimiento(e.target.value); }} />
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese fecha de nacimiento!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                   {/* <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom11">
                            <Form.Label>Puesto de trabajo</Form.Label>
                            <Form.Select 
                                defaultValue={props.puesto} 
                                onChange={(e)=>
                                    props.setPuesto(e.target.value)
                                } 
                                required
                            >
                                <option>Seleccione un puesto de trabajo</option>
                                {listaPuestos.map((value) => {
                                    return(<option value={value.CODIGO} key={value.CODIGO} >{value.DESCRIPCION}</option>)
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese su puesto de trabajo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>*/}
                    <hr />
                    <Button type="submit">Guardar</Button>
                    <Button variant='secondary' className="segundo-btn"
                        onClick={() => { props.setShowState(false); props.setValidatedCreate(false); }
                        }>Cancelar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}